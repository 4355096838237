<template>
    <div class="card-header pt-3 pb-0 position-relative">
      <div class="row">
        <div class="col-auto">
          <b-nav tabs fill @click="handleTabClick">
            <b-nav-item data-target="engagements" :active="activeComponent === 'engagements'">
              <router-link :to="{ name: 'checkin-admin-traction-engagements' }" class="text-decoration-none">
                <h6 class="m-0 font-weight-bold text-primary">
                  {{ $t('account-page-analytics.list_engagement') }}
                </h6>
              </router-link>
            </b-nav-item>
            <b-nav-item data-target="pci-emails" :active="activeComponent === 'pci-emails'">
              <router-link :to="{ name: 'checkin-admin-traction-pci-emails' }" class="text-decoration-none">
                <h6 class="m-0 font-weight-bold text-primary">{{ $t('account-page-analytics.pci_email_tab') }}</h6>
              </router-link>
            </b-nav-item>
            <b-nav-item data-target="my-page" :active="activeComponent === 'my-page'">
              <router-link :to="{ name: 'checkin-admin-traction-my-page' }" class="text-decoration-none">
                <h6 class="m-0 font-weight-bold text-primary">{{ $t('account-page-analytics.my_page_tab') }}</h6>
              </router-link>
            </b-nav-item>
          </b-nav>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import t from "typy";
  
  export default {
    name: 'TractionTabs',
    data() {
      return {
        activeComponent: ''
      }
    },
    created() {

      switch (this.$route.name) {
        case 'checkin-admin-traction-engagements':
          this.activeComponent = 'engagements';
          break;
        case 'checkin-admin-traction-pci-emails':
          this.activeComponent = 'pci-emails';
          break;
        case 'checkin-admin-traction-my-page':
          this.activeComponent = 'my-page';
          break;
        default:
          this.activeComponent = 'engagements'; 
      }
    },
    methods: {
      handleTabClick(event) {
        if (event.target) {
          const target = event.target.getAttribute('data-target');
          if (target) {
            this.activeComponent = target;
          }
        }
      }
    }
  }
  </script>
  