import moment from 'moment';
import t from "typy";
import TractionTabs from "../../components/traction-engagements/tabs.vue";
import { months } from "../../utils/mockup";

export default {
    components: { TractionTabs },
    data() {
        return {
            data: {
                meta: {
                    current_page: 0,
                    per_page: 0,
                    total: 0
                }
            },
            error: '',
            months: [],
            years: [],
            filter: {
                year: 0,
                month: ('0' + (new Date().getUTCMonth() + 1)).slice(-2),
                per_page: 10,
                mode: 'd',
            },
            days: [],
            config: {
                facilityName: 'Select Facility',
                checked_in_collapsed: 0,
                checked_out_collapsed: 0
            },
            daysOfMonth: [],
            total_daily: {
               my_page_login: {}
            }
        }
    },
    async mounted() {

        await this.populate_years()

    },
    methods: {
        yearDisplay(year) {
            const display = year != 0 ? year : '';
            return display;
        },
        clearData() {
            this.error = '';
            this.config.facilityName = 'Select Facility';
        },
        handleMonth() {
           this.clearData();
        },
        handleYear() {
            this.clearData();
        },
        async loadFiltered() {   
            if(this.filter['year'] !== 0) {
                this.clearData();
                this.$select_date();
                this.daysOfMonth = [];
                this.generateDaysWithMonths();
            } else {
                this.error = this.$t('error-messages.no-selected-year');
            }
        },
        async paginate(page) {

            var filter = {
                ...this.filter,
                page: page
            }

            // this.daysOfMonth = [];

            await this.list(filter)
        },
        async populate_years() {
            var date = new Date();
            for (var i = 2019; i <= date.getFullYear(); i++) {
                this.years.push(i);
            }
        },
        async $select_date() {
            await this.populate_dates(this.filter.year, this.filter.month);
            await this.list(this.filter);
            await this.facilityReport(this.filter)
        },
 
        async list(filter) {
            const response = await this.$lionheart.get('/v2/report/guest-activity-my-page', { params: filter })
            this.data = response.data;
        },

        async facilityReport (params) {
            const response = await this.$lionheart.get('/v2/report/guest-activity-my-page-per-facility', { params: params })
            this.total_daily = response.data.data[0] ? response.data.data[0] : {
               my_page_login: {}
            };
        },
        async $search_facility(data, name) {
            await this.populate_dates(this.filter.year,this.filter.month);
          
            const params = {
                ...this.filter,
                ...data
            }

            this.clearData();
           
            this.config.facilityName = name;

            await this.facilityReport(params);
        },
        async populate_dates(year, month) {
            this.days = [];
            month = parseInt(month) - 1;
            var date = new Date(year, month, 1)
            while (date.getMonth() == month) {
                this.days.push(('0' + (new Date(date).getDate())).slice(-2))
                date.setDate(date.getDate() + 1)
            }
        },

        generateDaysWithMonths() {
            // method to generate days in a month

            let month = parseInt(this.filter.month) - 1;
            let year = this.filter.year;

            let startDate = moment([year, month, 1]);
            let endDate = moment(startDate).endOf('month');

            while (startDate.isSameOrBefore(endDate, 'day')) {
                  this.daysOfMonth.push(startDate.date());
                  startDate.add(1, 'day');
            }
        }
    }
}
