
export const months = [
  {
    long_name: "January",
    short_name: "Jan",
    number_name: "01",
  },
  {
    long_name: "February",
    short_name: "Feb",
    number_name: "02",
  },
  {
    long_name: "March",
    short_name: "Mar",
    number_name: "03",
  },
  {
    long_name: "April",
    short_name: "Apr",
    number_name: "04",
  },
  {
    long_name: "May",
    short_name: "May",
    number_name: "05",
  },
  {
    long_name: "June",
    short_name: "Jun",
    number_name: "06",
  },
  {
    long_name: "July",
    short_name: "Jul",
    number_name: "07",
  },
  {
    long_name: "August",
    short_name: "Aug",
    number_name: "08",
  },
  {
    long_name: "September",
    short_name: "Sept",
    number_name: "09",
  },
  {
    long_name: "October",
    short_name: "Oct",
    number_name: "10",
  },
  {
    long_name: "November",
    short_name: "Nov",
    number_name: "11",
  },
  {
    long_name: "December",
    short_name: "Dec",
    number_name: "12",
  },
];